import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { useState, useContext } from "react";
import UserContext from './UserContext';
import { logEvent } from 'firebase/analytics';

export default function AppMenuSmall({ pages }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { analytics } = useContext(UserContext);

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
      <IconButton color="inherit" aria-label="menu" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {pages.filter(page => page.show()).map((page) => (
          <MenuItem
            key={page.label}
            onClick={() => {
              page.onClick?.();
              setAnchorEl(null);
              logEvent(analytics, 'menu_item_click', { menu_item: page.label });
            }}
            component={page.link ? RouterLink : 'div'}
            to={page.link}
          >
            {page.label}
          </MenuItem>
        ))}
      </Menu>
      <Typography
        variant="h5"
        component={RouterLink}
        to="/"
        sx={{
          m: 0.5,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'inherit',
          textDecoration: 'none',
        }}
        onClick={() => logEvent(analytics, 'menu_item_click', { menu_item: 'home' })}
      >
        FORMA
      </Typography>
    </Box>
  );
}
