import React from 'react';
import { Chip } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const getGoalLabel = (goal) => {
  switch(goal) {
    case 'compete':
      return 'Competitive';
    case 'health':
      return 'Staying healthy';
    case 'weight':
      return 'Losing weight';
    case 'fun':
      return 'Recreational';
    case 'sports':
      return 'Playing sports';
    default:
      return 'None';
  }
};

const ChipGoal = ({ goal }) => (
  <Chip
    variant="outlined"
    icon={<DirectionsRunIcon />}
    label={getGoalLabel(goal)}
  />
);

export default ChipGoal;
