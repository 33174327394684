import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid } from '@mui/material';

const ReviewSubmissionInvalidForm = ({ invalidReason, setInvalidReason, handleInvalid }) => {
  const [showCustomReason, setShowCustomReason] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setInvalidReason(value);
    setShowCustomReason(value === "other"); // Show text field if "other" is selected
  };

  const isButtonDisabled = () => {
    if (!invalidReason) return true;
    if (showCustomReason && invalidReason === 'other') return true;
    return false;
  };

  return (
    <form onSubmit={handleInvalid}>
      <Grid sx={{mt:1, mb:1}} container spacing={2} alignItems="center">
        <Grid item xs={12} md={8}>
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel id="invalid-reason-label">Invalid Reason</InputLabel>
            <Select
              labelId="invalid-reason-label"
              value={invalidReason}
              onChange={handleChange}
              required
            >
              <MenuItem value="">
                <em>--Select a reason--</em>
              </MenuItem>
              <MenuItem value="not-running">Not a running video</MenuItem>
              <MenuItem value="low-quality">Video quality is low</MenuItem>
              <MenuItem value="poor-lighting">Poor video lighting</MenuItem>
              <MenuItem value="bad-angle">Incorrect video angle</MenuItem>
              <MenuItem value="bad-clothing">Clothing obstructing view</MenuItem>
              <MenuItem value="other">Other (please specify)</MenuItem>
            </Select>
          </FormControl>
          {showCustomReason && (
            <TextField
              margin="normal"
              size="small"
              label="Specify Reason"
              fullWidth
              value={invalidReason === 'other' ? '' : invalidReason}
              onChange={(e) => setInvalidReason(e.target.value)}
              required={showCustomReason}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" type="submit" disabled={isButtonDisabled()} fullWidth>
            Mark as Invalid
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReviewSubmissionInvalidForm;
