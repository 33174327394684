import React, { useContext } from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import UserContext from './UserContext';
import { logEvent } from 'firebase/analytics';

const Footer = () => {
  const { analytics } = useContext(UserContext);

  return (
    <Box sx={{ p: 4}} component="footer">
      <Container maxWidth="lg">
        <Box mt={2} align="center">
          <Link
            href="mailto:info@forma.run"
            color="inherit"
            underline="none"
            onClick={() => logEvent(analytics, 'footer_click', { link: 'contact' })}>
            <MailOutlineIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            Get in touch
          </Link>
          <Link
            href="/privacy"
            color="inherit"
            underline="none"
            onClick={() => logEvent(analytics, 'footer_click', { link: 'privacy' })}
            sx={{ ml: 2, mr: 2 }}>
            Privacy
          </Link>
          <Link
            href="/terms"
            color="inherit"
            underline="none"
            onClick={() => logEvent(analytics, 'footer_click', { link: 'terms' })}>
            Terms
          </Link>
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          {'© '}
          {new Date().getFullYear()}
          {' '}
          Forma Technologies - Designed in Boston
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
