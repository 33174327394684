import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  components: {
    // MuiDialog: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: grey[900], // or blueGrey[900]
    //     },
    //   },
    // },
  },
  palette: {
    // primary: {
    //   main: '#2c387e', // deep blue
    //   light: '#545ebd', // light blue
    //   dark: '#001a48', // very dark blue
    // },
    // secondary: {
    //   main: '#ff6f00', // deep orange
    //   light: '#ff9e33', // light orange
    //   dark: '#c43e00', // very dark orange
    // },
    // error: {
    //   main: '#f44336', // bright red
    //   light: '#ff7961', // light red
    //   dark: '#ba000d', // dark red
    // },
    // warning: {
    //   main: '#ff9800', // bright amber
    //   light: '#ffb74d', // light amber
    //   dark: '#f57c00', // dark amber
    // },
    // info: {
    //   main: '#2196f3', // bright blue
    //   light: '#64b5f6', // light blue
    //   dark: '#1976d2', // dark blue
    // },
    // success: {
    //   main: '#4caf50', // bright green
    //   light: '#81c784', // light green
    //   dark: '#388e3c', // dark green
    // },
    background: {
      default: '#f8f8f8', // grey
      // paper: '#1d1d1d', // dark gray
    },
    // text: {
    //   primary: 'rgba(255, 255, 255, 0.87)', // white text, used for most text.
    //   secondary: 'rgba(255, 255, 255, 0.54)', // medium emphasis white text.
    //   disabled: 'rgba(255, 255, 255, 0.38)', // disabled white text.
    //   hint: 'rgba(255, 255, 255, 0.38)', // text to be used when a component is in a state that can't receive input.
    // }
  },
});

export default Theme;
