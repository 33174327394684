import React, { useContext, useEffect } from 'react';
import UserContext from './UserContext';
import { Button, Typography, Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import './HomePage.css';

const HomePageTheme = createTheme({
  palette: {
    text: {
      primary: "#ffffff",
    },
    background: {
      default: '#282c34',
    },
  },
});

const HomePage = () => {
  useEffect(() => {
    document.title = "Forma | Free Running Form Analysis";
  }, []);
  const { analytics } = useContext(UserContext);
  return (
    <ThemeProvider theme={HomePageTheme}>
      <CssBaseline />
      <div className="home-page" style={{ marginTop: -64 }}>
        <Container sx={{mt: 0}}>
          <Typography variant="h3" align="center" gutterBottom>
            Free Expert-Level Running Analysis
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            Your Personal Guide Towards More Efficient, Pain-Free Running
          </Typography>
          <Button
            size="large"
            sx={{mt: 5}}
            variant="contained"
            component={RouterLink}
            to="/upload"
            disableElevation
            onClick={() => logEvent(analytics, 'button_click', { button_name: 'upload_cta' })}
          >
            Upload Your Video
          </Button>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
