import React, { useState, useEffect, useContext } from 'react';
import {
  Container, Typography, CircularProgress, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Link as MuiLink
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


import { collection, getDocs, limit, where, orderBy, query } from 'firebase/firestore';
import UserContext from './UserContext';
import { toast } from 'react-toastify';

const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const { db } = useContext(UserContext);
  const [pendingSubmissions, setPendingSubmissions] = useState([]);
  const [reviewedSubmissions, setReviewedSubmissions] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    document.title = "Forma | Admin";

    const fetchSubmissions = async (status, setState) => {
      try {
        const querySnapshot = await getDocs(query(
          collection(db, 'submissions'),
          where('status', '==', status),
          orderBy('timestamp', 'desc'),
          limit(200)));
        const submissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setState(submissions);
      } catch (error) {
        console.error(`Error fetching ${status} submissions:`, error);
        toast.error(`Failed to load ${status} submissions.`, { toastId: `adminError-${status}` });
      }
    };

    const fetchPendingSubmissions = () => fetchSubmissions('pending', setPendingSubmissions);
    const fetchReviewedSubmissions = () => fetchSubmissions('reviewed', setReviewedSubmissions);

    fetchPendingSubmissions();
    fetchReviewedSubmissions();
    setLoading(false);
  }, [db]);

  const combinedSubmissions = [
    ...pendingSubmissions.map(sub => ({ ...sub, status: 'Pending' })),
    ...reviewedSubmissions.map(sub => ({ ...sub, status: 'Reviewed' }))
  ];

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Admin Page
      </Typography>

      {/* Table for Submissions */}
      <Typography variant="h5" align="center" gutterBottom>
        Submissions
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : combinedSubmissions.length === 0 ? (
        <Typography align="center">No submissions available.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Angle</TableCell>
                <TableCell>Symptoms</TableCell>
                <TableCell>Video</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedSubmissions.map((submission) => (
                <TableRow key={submission.id}>
                  <TableCell component="th" scope="row">
                    <MuiLink
                      component={RouterLink}
                      to={submission.status === 'Reviewed'
                        ? `/admin/user/${submission.userId}/results/${submission.id}`
                        : `/admin/review/${submission.id}`}
                    >
                      {submission.id}
                    </MuiLink>
                  </TableCell>
                  <TableCell>
                   <MuiLink
                      component={RouterLink}
                      to={`/admin/user/${submission.userId}/results`}
                    >
                      {submission.userId}
                    </MuiLink>
                  </TableCell>
                  <TableCell>{new Date(submission.timestamp?.seconds * 1000).toLocaleString()}</TableCell>
                  <TableCell>{submission.videoAngle || 'N/A'}</TableCell>
                  <TableCell>{submission.symptomsSummary || 'N/A'}</TableCell>
                  <TableCell>
                    <MuiLink
                      href={submission.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={() => setVideoUrl(submission.videoUrl)}
                      onMouseLeave={() => setVideoUrl('')}
                    >
                      View
                    </MuiLink>
                  </TableCell>
                  <TableCell>{submission.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <video
        src={videoUrl}
        controls
        autoPlay
        loop
        muted
        style={{
          visibility: videoUrl ? 'visible' : 'hidden',
          position: 'fixed',
          bottom: 10,
          left: 10,
          maxWidth: '200px'
        }}
      >
        Your browser does not support the video tag.
      </video>
    </Container>
  );
};

export default AdminPage;
