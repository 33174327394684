import { TextField, IconButton, FormControl, Box } from '@mui/material';
import { AddCircleOutline, HighlightOff } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

const ReviewTips = ({ tips, setTips }) => (
  <fieldset>
    <legend>Tips</legend>
    {tips.map((tip, index) => (
      <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 1 }}>
        <FormControl sx={{mb: 1}} fullWidth variant="outlined">
          <TextField
            label="Title"
            size="small"
            required
            value={tip.title}
            onChange={(e) => {
              const newTips = [...tips];
              newTips[index].title = e.target.value;
              setTips(newTips);
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            label="Description"
            multiline
            size="small"
            required
            rows={4}
            value={tip.description}
            onChange={(e) => {
              const newTips = [...tips];
              newTips[index].description = e.target.value;
              setTips(newTips);
            }}
          />
        </FormControl>
        <ReactMarkdown>{tip.description}</ReactMarkdown>
        <IconButton onClick={() => {
          const newTips = [...tips];
          newTips.splice(index, 1);
          setTips(newTips);
        }}>
          <HighlightOff />
        </IconButton>
      </Box>
    ))}
    <IconButton onClick={() => setTips([...tips, { title: '', description: '' }])}>
      <AddCircleOutline />
    </IconButton>
  </fieldset>
);

export default ReviewTips;
