import React, { useCallback, useContext, useEffect, useState } from "react";
import AppMenuLarge from "./AppMenuLarge";
import AppMenuSmall from "./AppMenuSmall";
import UserContext from "./UserContext";
import { signOut } from "firebase/auth";
import { AppBar, Toolbar, Container } from '@mui/material';

const AppMenu = () => {
  const { user, auth } = useContext(UserContext);
  const [pages, setPages] = useState([]);

  const handleSignOut = useCallback(() => {
    signOut(auth);
  }, [auth]);

  useEffect(() => {
    setPages([
      { label: 'Upload', link: '/upload', show: () => true },
      { label: 'Results', link: '/results', show: () => true },
      { label: 'Pricing', link: '/pricing', show: () => true },
      // { label: 'Admin', link: '/admin', show: () => user },
      { label: 'Sign In', link: '/signin', show: () => !user },
      { label: `Sign Out (${user?.email})`, show: () => user, onClick: handleSignOut },
    ]);
  }, [user, handleSignOut]);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AppMenuLarge pages={pages} />
          <AppMenuSmall pages={pages} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppMenu;
