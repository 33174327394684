import React, { useState, useContext } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Rating } from '@mui/lab';
import { Fab, Dialog, Box, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import UserContext from './UserContext';

const Feedback = () => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rating, setRating] = useState(0);
  const [open, setOpen] = useState(false);
  const { user, db } = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => setFeedback(event.target.value);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const feedbackCollectionRef = collection(db, 'feedback');
      await addDoc(feedbackCollectionRef, {
        userId: user.uid,
        feedbackText: feedback,
        rating: rating,
        timestamp: new Date()
      });
      toast.success("Thank you for the feedback!");
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error(`Failed to submit feedback. ${error.message}`, { toastId: 'submitFeedbackError' });
    }
    setIsSubmitting(false);
    setFeedback('');
    setRating(0);
    handleClose();
  };

  return (
    <>
      <Fab
        color="background"
        aria-label="feedback"
        size="medium"
        variant="extended"
        onClick={handleOpen}
      >
        <FeedbackOutlinedIcon sx={{ mr: 1 }} />
        Share Your Thoughts
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle>Share Your Thougths</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Rating
              name="feedback-rating"
              value={rating}
              size="large"
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              label="Enter feedback"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={feedback}
              onChange={handleChange}
            />
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={!feedback.trim() || isSubmitting}
              sx={{ alignSelf: 'flex-start' }}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Feedback;
