import React, { useState, useEffect, useContext } from 'react';
import { Button, Box, CircularProgress, Container, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Stack, Paper } from "@mui/material";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import ChipCameraAngle from './ChipCameraAngle';
import ChipStatus from './ChipStatus';
import UserContext from './UserContext';
import PendingImage from './PendingImage';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { logEvent } from 'firebase/analytics';
import useMediaQuery from '@mui/material/useMediaQuery';

const ResultsPage = () => {
  const { user, db, analytics } = useContext(UserContext);
  const { userId } = useParams();  // Get userId from URL if it exists
  const actualUserId = userId || user.uid;  // Use URL userId if provided, otherwise use logged-in user's ID
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [submissions, setSubmissions] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    document.title = "Forma | Results";

    const fetchSubmissions = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(db, 'submissions'), where('userId', '==', actualUserId), orderBy('timestamp', 'desc')));
        const userSubmissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubmissions(userSubmissions);
      } catch (error) {
        console.error('Error fetching submissions:', error);
        toast.error('Error loading results. Please retry.', { toastId: 'resultsError' });
        logEvent(analytics, 'page_load_error', {error: error.message, page: 'results'});
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [actualUserId, db, analytics]);

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Results
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      ) : submissions.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh">
          <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
            You have not uploaded any videos.
          </Typography>
          <Button
             sx={{mt: 2, mb: 2}}
            size="large"
            component={RouterLink}
            to="/upload"
            variant="outlined"
            color="primary"
          >
            Upload your first video
          </Button>
          <PendingImage altText="no-videos-uploaded"/>
        </Box>
      ) : (
        <>
          <Typography  marginBottom={2} variant="h6" align="center" gutterBottom>
            Your Past Submissions
          </Typography>
          <TableContainer component={Paper} elevation={0} variant="outlined">
            <Table>
              <TableBody>
                {submissions.map(submission => (
                  <TableRow key={submission.id}
                            hover
                            component={RouterLink}
                            to={`${location.pathname}/${submission.id}`}
                            style={{ textDecoration: 'none' }}>
                    <TableCell>
                      {
                        new Date(submission.timestamp?.seconds * 1000).toLocaleDateString('en-US',
                          isSmallScreen
                          ? { day: 'numeric', month: 'short', year: 'numeric' }
                          : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }
                        )
                      }
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        {submission.results && (
                          <ChipCameraAngle results={submission.results} />
                        )}
                        <ChipStatus status={submission.status} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
}

export default ResultsPage;
