import * as React from 'react';
import { Chip } from '@mui/material';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';

const ChipCameraAngle = ({ results }) => {
  return (
    <Chip
      icon={<PhotoCameraFrontIcon />}
      variant="outlined"
      label={results?.sideViewData ? 'Side view' : 'Back view'}
    />
  );
};

export default ChipCameraAngle;
