import React, { useState, useContext, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Container, TextField, Typography, Box, Alert, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';
import { logEvent } from 'firebase/analytics';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';

function SignInPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { user, auth, analytics } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const from = location.state?.from || '/';

  useEffect(() => {
    document.title = "Forma | Log In or Sign Up";
    if (user) {
      navigate(from);
    }
  }, [user, navigate, from]);

  const validateInput = () => {
    const isEmailEmpty = !email;
    const isPasswordEmpty = !password;
    setEmailError(isEmailEmpty);
    setPasswordError(isPasswordEmpty);
    return !isEmailEmpty && !isPasswordEmpty;
  };

  const getFriendlyErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'Invalid email address.';
      case 'auth/user-not-found':
        return 'Email not found.'
      case 'auth/wrong-password':
        return 'Wrong password.';
      case 'auth/invalid-password':
        return 'Invalid password.';
      case 'auth/email-already-exists':
      case 'auth/email-already-in-use':
        return 'This email is already in use by another account.';
      case 'auth/weak-password':
        return 'Try a stronger password.';
      case 'auth/popup-blocked':
        return 'Popup blocked by browser.'
      case 'auth/user-disabled':
        return 'Account is disabled. Please contact support.';
      default:
        return 'An unexpected error occurred. Please retry or contact support.';
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!validateInput()) {
      return;
    }

    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      logEvent(analytics, 'sign_in_success');
    } catch (err) {
      console.error(err);
      setError(getFriendlyErrorMessage(err.code));
      logEvent(analytics, 'sign_in_error', {error: err.message});
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setError('');

    if (!validateInput()) {
      return;
    }

    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      logEvent(analytics, 'sign_up_success');
    } catch (err) {
      console.error(err);
      setError(getFriendlyErrorMessage(err.code));
      logEvent(analytics, 'sign_up_error', {error: err.message});
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    // setLoading(true);
    try {
      await signInWithPopup(auth, provider);
      logEvent(analytics, 'google_sign_in_success');
      navigate(from);
    } catch (err) {
      console.error(err);
      logEvent(analytics, 'google_sign_in_error', { error: err.message });
      if (err.code !== 'auth/cancelled-popup-request' && err.code !== 'auth/popup-closed-by-user') {
        setError('Failed to sign in with Google.');
      }
    }
    // finally {
    //   setLoading(false);
    // }
  };

  return (
    <Container maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" marginTop={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Log In or Sign Up
        </Typography>
        <form onSubmit={handleSubmit} noValidate autoComplete="on">
          <TextField
            error={emailError}
            helperText={emailError ? "Email is required" : ""}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            error={passwordError}
            helperText={passwordError ? "Password is required" : ""}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<EmailIcon />}
            sx={{mt: 2}}
            loading={loading}
          >
            Log In with Email
          </LoadingButton>
          <LoadingButton
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<EmailIcon />}
            sx={{mt: 2, mb: 1}}
            onClick={handleSignUp}
            loading={loading}
          >
            Sign Up with Email
          </LoadingButton>
          <Divider style={{ width: '100%', margin: '8px 0' }}>
            <Typography variant='body2' color="textSecondary">or</Typography>
          </Divider>
          <LoadingButton
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<GoogleIcon />}
            sx={{mt: 1}}
            onClick={handleGoogleSignIn}
            loading={loading}
          >
            Continue with Google
          </LoadingButton>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
      </Box>
    </Container>
  );
}

export default SignInPage;
