import { TextField, IconButton, FormControl, FormControlLabel, Checkbox, Box } from '@mui/material';
import { AddCircleOutline, HighlightOff } from '@mui/icons-material';

const ReviewHighlights = ({ highlights, setHighlights }) => (
  <fieldset>
    <legend>Highlights</legend>
    {highlights.map((highlight, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <FormControl fullWidth variant="outlined" sx={{ mr: 1 }}>
          <TextField
            label="Enter highlights (60 chars)"
            size="small"
            required
            value={highlight.text}
            onChange={(e) => {
              const newHighlights = [...highlights];
              newHighlights[index].text = e.target.value;
              setHighlights(newHighlights);
            }}
            inputProps={{ maxLength: 60 }}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={highlight.isGood}
              onChange={(e) => {
                const newHighlights = [...highlights];
                newHighlights[index].isGood = e.target.checked;
                setHighlights(newHighlights);
              }}
              color="primary"
            />
          }
          label="Good"
        />
        <IconButton onClick={() => {
          const newHighlights = [...highlights];
          newHighlights.splice(index, 1);
          setHighlights(newHighlights);
        }}>
          <HighlightOff />
        </IconButton>
      </Box>
    ))}
    <IconButton onClick={() => setHighlights([...highlights, { text: '', isGood: true }])}>
      <AddCircleOutline />
    </IconButton>
  </fieldset>
);

export default ReviewHighlights;
