import { useContext } from 'react';
import { Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import UserContext from './UserContext';
import { logEvent } from 'firebase/analytics';

export default function AppMenuLarge({ pages }) {
  const { analytics } = useContext(UserContext);
  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1}}>
      <Typography
        variant="h5"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          m: 0.5,
          color: 'inherit',
          textDecoration: 'none',
        }}
        onClick={() => logEvent(analytics, 'menu_item_click', { menu_item: 'home' })}
      >
        FORMA
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
        {pages.filter(page => page.show()).map((page) => (
          <Button
            key={page.label}
            onClick={() => {
              page.onClick?.();
              logEvent(analytics, 'menu_item_click', { menu_item: page.label });
            }}
            component={page.link ? RouterLink : 'div'}
            to={page.link}
            color="inherit"
          >
            {page.label}
          </Button>
        ))}
    </Box>
  );
}
