import { useContext, useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { logEvent } from 'firebase/analytics';
import { ref, getDownloadURL } from 'firebase/storage';
import { CardMedia, Tabs, Tab, Box, CircularProgress } from '@mui/material';
import UserContext from './UserContext';

const VideoTimestampCarousel = ({ videoUrl, videoAngle, userId }) => {
  const [tabValue, setTabValue] = useState(0);
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [frames, setFrames] = useState([]);

  const nameMapping = {
    IC: { label: 'Initial Contact', smallLabel: 'Initial', order: 1 },
    LR: { label: 'Loading Response', smallLabel: 'Load', order: 2 },
    MSt: { label: 'Mid Stance', smallLabel: 'Mid', order: 3 },
    LSt: { label: 'Late Stance', smallLabel: 'Late', order: 4 },
    MSw: { label: 'Mid Swing', smallLabel: 'Swing', order: 5 },
  };

  // const phases = videoAngle === 'side' ? ['LR', 'MSt', 'LSt', 'MSw'] : ['IC', 'MSt', 'MSw'];

  const { storage, analytics } = useContext(UserContext);

  const decodedVideoUrl = decodeURIComponent(videoUrl);
  const videoPath = new URL(decodedVideoUrl).pathname;
  const videoName = videoPath.split('/').pop();

  useEffect(() => {
    const phases = videoAngle === 'side' ? ['LR', 'MSt', 'LSt', 'MSw'] : ['IC', 'MSt', 'MSw'];

    const promises = [];

    phases.forEach((phase) => {
      const path = `videos/${userId}/${videoName}_${phase}`;
      const imageRef = ref(storage, path);

      promises.push(getDownloadURL(imageRef));
    });

    Promise.all(promises)
      .then((urls) => {
        setFrames(urls.map((url, index) => ({ img: url, text: phases[index] })));
      })
      .catch((error) => {
        console.error("Error getting download URLs: ", error);
      });
  }, [storage, videoUrl, userId, videoName, videoAngle]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    logEvent(analytics, 'phases_tab_click', { tab_name: newValue, videoAngle: videoAngle });
  };

  if (frames.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="flex-start" height={720} paddingTop={5}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'center' } }}>
        <Tabs value={tabValue} onChange={handleChange}>
          {frames.map((frame, index) => (
            <Tab key={index} label={small ? nameMapping[frame.text].smallLabel : nameMapping[frame.text].label} />
          ))}
        </Tabs>
        {frames.map((frame, index) => (
          <Box key={index} hidden={tabValue !== index} sx={{ width: { xs: '100%', md: '100%' } }}>
            <CardMedia
              component="img"
              image={frame.img}
              alt={`Frame at ${frame.text} phase`}
              style={{ height: 720, width: '100%' }}
            />
          </Box>
        ))}
      </Box>
    );
  }
};

export default VideoTimestampCarousel;
