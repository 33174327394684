import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from "@mui/material";
import AdminPage from './AdminPage';
import Footer from './Footer';
import HomePage from './HomePage';
import AppMenu from "./AppMenu";
import PricingPage from './PricingPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import ProtectedRoute from './ProtectedRoute';
import ReviewSubmission from './ReviewSubmission';
import ResultsPage from './ResultsPage';
import ResultDetailPage from './ResultDetailPage';
import SignInPage from './SignInPage';
import Theme from './Theme';
import TosPage from './TosPage';
import UploadPage from './UploadPage';
import UserContext from './UserContext';
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

// Initialize your Firebase app
const firebaseConfig = {
  apiKey: 'AIzaSyB3-7aj5izCwAF9RP9pA1aqzyMKHlH8AAA',
  authDomain: 'forma-run.firebaseapp.com',
  projectId: 'forma-run',
  storageBucket: 'forma-run.appspot.com',
  messagingSenderId: '273256122372',
  appId: '1:273256122372:web:a2da107bc6fe591ad34d51',
};

const app = initializeApp(firebaseConfig);

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const analytics = getAnalytics(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <Router>
      <div className="App" style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
              <CircularProgress />
            </Box>
          ) : (
            <UserContext.Provider value={{ user, app, auth, db, storage, analytics }}>
              <AppMenu />
              <Box style={{flexGrow: 1}}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/upload" element={<UploadPage />} />
                  <Route path="/admin" element={<ProtectedAdminRoute><AdminPage /></ProtectedAdminRoute>} />
                  <Route path="/admin/review/:submissionId" element={<ProtectedAdminRoute><ReviewSubmission /></ProtectedAdminRoute>} />
                  <Route path="/admin/user/:userId/results" element={<ProtectedAdminRoute><ResultsPage /></ProtectedAdminRoute>} />
                  <Route path="/admin/user/:userId/results/:submissionId" element={<ProtectedAdminRoute><ResultDetailPage /></ProtectedAdminRoute>} />
                  <Route path="/results" element={<ProtectedRoute><ResultsPage /></ProtectedRoute>} />
                  <Route path="/results/:submissionId" element={<ProtectedRoute><ResultDetailPage /></ProtectedRoute>} />
                  <Route path="/signin" element={<SignInPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/terms" element={<TosPage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Box>
              <Footer />
            </UserContext.Provider>
          )}
          <ToastContainer />
        </ThemeProvider>
      </div>
    </Router>
    );
}

export default App;
