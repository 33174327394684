import * as React from 'react';
import { Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

const ChipStatus = ({ status }) => {
  const getColor = (status) => {
    switch (status) {
      case 'invalid':
        return 'error';
      case 'reviewed':
        return 'success';
      case 'pending':
        return 'info';
      default:
        return 'primary';
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'invalid':
        return <CancelIcon />;
      case 'reviewed':
        return <CheckCircleIcon />;
      case 'pending':
        return <PendingIcon />;
      default:
        return <PendingIcon />;
    }
  };

  return (
    <Chip
      icon={getIcon(status)}
      label={status.charAt(0).toUpperCase() + status.slice(1)}
      color={getColor(status)} />
  );
};

export default ChipStatus;
