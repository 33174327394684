import React from 'react';
import pendingImage from './assets/pending.png';

const PendingImage = ({ altText }) => {
  return (
    <div style={{ position: 'relative', paddingBottom: '35.12%' }}>
      <img src={pendingImage} alt={altText} style={{ maxWidth: "100%", height: "auto" }}/>
    </div>
  );
}

export default PendingImage;
