import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';

function ProtectedRoute({ children }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/signin', { state: { from: location }, replace: true });
    }
  }, [user, navigate, location]);

  return user ? children : null;
}

export default ProtectedRoute;
