import React, { useState, useContext } from 'react';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { Send as SendIcon, Replay as ReplayIcon } from '@mui/icons-material';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import UserContext from './UserContext';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import { Box, TextField, Button, Typography, Card, Stack, Chip } from '@mui/material';

export default function ResultsChat({ tips, highlights, messages, subjectId, submissionId }) {
  const { user, db, analytics } = useContext(UserContext);
  const [newMessage, setNewMessage] = useState('');
  const [messageList, setMessageList] = useState(messages || []);

  const lastMsg = messageList.length > 0 ? messageList[messageList.length - 1] : null;

  const updateLastMessageStatus = (status) => {
    setMessageList((prevMessages) =>
      prevMessages.map((msg, index) =>
        index === prevMessages.length - 1 ? { ...msg, status } : msg
      )
    );
  };

  const handleSendNew = async () => {
    if (newMessage.trim() === '') return;
  
    const messageToSend = {
      text: newMessage.trim(),
      isSenderUser: subjectId === user.uid,
      status: 'sending',
    };
  
    setMessageList((prevMessages) => [...prevMessages, messageToSend]);
    setNewMessage('');
    sendMessageToFirestore(messageToSend);
  };
  
  const handleRetry = async () => {
    updateLastMessageStatus('sending');
    sendMessageToFirestore(lastMsg);
  };

  const sendMessageToFirestore = async (message) => {
    try {
      await addDoc(collection(db, 'messages'), {
        text: message.text,
        senderId: user.uid,
        subjectId: subjectId,
        submissionId: submissionId,
        timestamp: serverTimestamp(),
      });
      updateLastMessageStatus('sent');
    } catch (err) {
      console.error(err);
      toast.error(`Failed to send the message. Please retry or contact support. ${err.message}`, { toastId: 'messageError' });
      logEvent(analytics, 'send_message_error', {error: err.message, page: 'results_detail'});
      updateLastMessageStatus('failed');
    }
  };

  return (
    <Card variant="outlined" elevation={0} sx={{p: 2}}>
      {highlights && highlights.length > 0 && (
        <Stack mb={2} useFlexGap direction="row" spacing={{ xs: 0.5, sm: 1 }} flexWrap="wrap">
          {highlights.map((highlight, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={highlight.text}
              icon={highlight.isGood ?  <ThumbUpOutlinedIcon/> : <ThumbDownOutlinedIcon/>}
              color={highlight.isGood ? 'success' : 'error'}/>
          ))}
        </Stack>
      )}

      {tips.map((tip, index) => (
        <Box key={index} mb={3}>
          <Typography gutterBottom variant="h6">
            {tips.length === 1 
              ? `Advice: ${tip.title}` 
              : `${index + 1}. ${tip.title}`}
          </Typography>
          <Typography variant="body1">
          <ReactMarkdown components={{p: ({node, children}) => <>{children}</>}}>
              {tip.description}
          </ReactMarkdown>
          </Typography>
        </Box>
      ))}

      {messageList.map((message, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: message.isSenderUser ? 'flex-end' : 'flex-start',
            marginBottom: 1,
          }}
        >
          <Typography
            sx={{
              backgroundColor:
              message.status === 'failed'
                ? 'error.light'
                : message.isSenderUser
                ? 'primary.light' : 'grey.200',
              color: (message.status === 'failed' || message.isSenderUser) ? 'primary.contrastText' : 'text.primary',
              padding: 1,
              borderRadius: 1,
              maxWidth: '80%',
            }}
          >
            <ReactMarkdown components={{p: ({node, children}) => <>{children}</>}}>
              {message.text}
            </ReactMarkdown>
          </Typography>
        </Box>
      ))}

      {lastMsg && lastMsg.isSenderUser && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" color="text.secondary" align="center">
            You will receive an email when the coach responds to your message.
          </Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 1, pt: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          label={lastMsg?.status === 'failed' ? 'Failed to send the message. Please retry' : 'Questions? Message the coach'}
          value={newMessage}
          size="small"
          multiline 
          minRows={1}
          maxRows={4}
          disabled={lastMsg?.status === 'sending' || lastMsg?.status === 'failed'}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); 
              handleSendNew();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={lastMsg?.status === 'sending'}
          onClick={lastMsg?.status === 'failed' ? handleRetry : handleSendNew}
        >
          {lastMsg?.status === 'failed' ? <ReplayIcon /> : <SendIcon />}
        </Button>
      </Box>
    </Card>
  );
}
