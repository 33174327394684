import React from 'react';
import { Stack, Chip } from '@mui/material';
import ChipStatus from './ChipStatus';
import ChipCameraAngle from './ChipCameraAngle';
import ChipGoal from './ChipGoal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';

const ResultDetailChipBar = ({ submission }) => {
  return (
    <Stack mb={2} useFlexGap direction="row" spacing={{ xs: 0.5, sm: 1 }} flexWrap="wrap" justifyContent="center">
      <ChipStatus status={submission.status} />
      <Chip
        variant="outlined"
        icon={<CalendarMonthIcon />}
        label={new Date(submission.timestamp.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} />
      {submission.results && (
        <ChipCameraAngle results={submission.results} />
      )}
      <ChipGoal goal={submission.goal} />
      {submission.symptomsSummary && (
        <Chip variant="outlined" label={submission.symptomsSummary} icon={<MedicalInformationOutlinedIcon />} />
      )}
    </Stack>
  );
}

export default ResultDetailChipBar;
