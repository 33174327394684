import { createContext } from 'react';

const UserContext = createContext({
  user: null,
  app: null,
  auth: null,
  db: null,
  storage: null,
  analytics: null,
});

export default UserContext;
