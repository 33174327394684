import React, { useState, useContext, useEffect, useRef } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import UserContext from './UserContext';
import {
  Container, Typography, Box, Tab, Tabs, Button, Grid,
  CircularProgress, FormControl, InputLabel, Select, MenuItem,
  Dialog, LinearProgress, Paper, TextField
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import PendingImage from './PendingImage';
import posterImage from './assets/poster.jpg';
import videoAngle from './assets/angle.mp4';
import videoClothes from './assets/clothes.mp4';
import videoFps from './assets/fps.mp4';
import videoLength from './assets/length.mp4';
import videoPace from './assets/pace.mp4';
import videoSetting from './assets/setting.mp4';

function VerticalTabPanel(props) {
  const { text, videoUrl, value, index, ...other } = props;
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display="flex" flexDirection={isLargeScreen ? 'row' : 'column'} alignItems="center">
            <Box flexGrow={1} p={isLargeScreen ? 3 : 1} display="flex" justifyContent="center" alignItems="center">
              <Typography align="center" variant={isLargeScreen ? 'h6' : 'body1'}>
                {text}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: isLargeScreen ? 'flex-end' : 'center',
                width: isLargeScreen ? 'auto' : '100%',
              }}
            >
              <video
                style={{
                  maxHeight: '300px',
                  height: isLargeScreen ? '300px' : 'auto',
                  width: isLargeScreen ? '337.5px' : '100%',
                }}
                autoPlay
                muted
                loop
                playsInline
                controls
                poster={posterImage}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Box>
        )}
      </Box>
    );
}

function UploadPage() {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [goal, setGoal] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [videoPending, setVideoPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [symptomsText, setSymptomsText] = useState('');
  const inputFileRef = useRef(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    ".MuiDialog-paper": {
      margin: theme.spacing(1),
    },
  }));

  const { user, db, storage, analytics } = useContext(UserContext);

  useEffect(() => {
    document.title = "Forma | Upload";

    if (!user) {
      setIsLoading(false);
      return;
    }

    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(db, 'submissions'),
          where('userId', '==', user.uid),
          where('status', '==', 'pending'));
        const querySnapshot = await getDocs(q);
        setVideoPending(!querySnapshot.empty);
      } catch (err) {
        console.error(err);
        toast.error(`Failed to load data. ${err.message}`, { toastId: 'videoQueryError' });
        logEvent(analytics, 'page_load_error', {error: err.message, page: 'upload'});
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user, db, analytics]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // File size is less than 100MB
      if (file.size > 100 * 1024 * 1024) {
        toast.error('File size must be less than 100MB.', { toastId: 'videoSizeError' });
        inputFileRef.current.value = '';
        logEvent(analytics, 'video_select_error', {error: 'size'});
        return;
      }

      // File is a video format
      if (!file.type.startsWith('video/')) {
        toast.error('File must be a video.', { toastId: 'videoTypeError' });
        inputFileRef.current.value = '';
        logEvent(analytics, 'video_select_error', {error: 'type'});
        return;
      }

      setPreviewUrl(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (file && user && goal) {
      // Generate a timestamp
      const timestamp = Date.now();

      // Generate the storage reference
      const storageRef = ref(storage, `videos/${user.uid}/${timestamp}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Progress function
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
        },
        (error) => {
          console.error(error);
          toast.error(`Failed to upload video. Please retry or contact support.`, { toastId: 'videoUploadError' });
          setIsUploading(false);
          logEvent(analytics, 'video_upload_error', {error: error.message});
        },
        async () => {
          try {
            // Get the download URL after successful upload
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            
            // Attempt to add the submission to Firestore
            const submissionsCollectionRef = collection(db, 'submissions');
            const docRef = await addDoc(submissionsCollectionRef, {
              userId: user.uid,
              videoUrl: url,
              timestamp: new Date(),
              goal: goal,
              symptomsText: symptomsText,
              status: 'pending'
            });
            
            toast.success("Video uploaded successfully. You will receive an email with results soon.");
            navigate(`/results/${docRef.id}`);
            logEvent(analytics, 'video_upload_success');
          } catch (error) {
            console.error('Add submission error:', error);
            toast.error(`Failed to submit video. Please retry or contact support.`, { toastId: 'submissionError' });
            logEvent(analytics, 'submission_error', { error: error.message });
          } finally {
            setIsUploading(false);
          }
        }
      );
      setIsUploading(true);
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Upload a Video
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
            <CircularProgress />
        </Box>
      ) : videoPending ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh">
          <Typography variant="h6" gutterBottom align="center">
            Your video is currently being processed. You will receive an email when results are ready.
          </Typography>
          <PendingImage altText="processing"/>
        </Box>
      ) : (
        <>
          <Typography variant="h6" align="center" gutterBottom>
            Tips for a Good Video
          </Typography>
          <Paper elevation={0} variant="outlined" sx={{ overflow: 'hidden', mb: 2 }}>
            <Grid container spacing={2} >
              <Grid item xs={3} md={2}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={selectedTab}
                  onChange={(event, newValue) => {
                    setSelectedTab(newValue);
                    logEvent(analytics, 'tutorial_tab_click', { tab_name: newValue });
                  }}
                  aria-label="Tips"
                  sx={{
                    '& .MuiTab-root': {
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                      minWidth: 'auto',
                      padding: '6px 12px',
                      textAlign: 'left'
                    }
                  }}
                >
                  <Tab label="Angle" />
                  <Tab label="Setting" />
                  <Tab label="Clothes" />
                  <Tab label="FPS" />
                  <Tab label="Pace" />
                  <Tab label="Length" />
                </Tabs>
              </Grid>
              <Grid item xs={9} md={10}>
                <VerticalTabPanel
                  value={selectedTab}
                  index={0}
                  text="Record back view or side view. 90-degree angles. Full body height. Camera should be at the level of the feet looking up."
                  videoUrl={videoAngle}
                />
                <VerticalTabPanel
                  value={selectedTab}
                  index={1}
                  text="Either treadmill or outdoor running is fine. Video should be good quality and well-lit."
                  videoUrl={videoSetting}
                />
                <VerticalTabPanel
                  value={selectedTab}
                  index={2}
                  text="Tight clothes to keep knees, hips, and ankles clearly visible. No baggy clothes or very long shirts. Good: shorts, leggings, etc."
                  videoUrl={videoClothes}
                />
                <VerticalTabPanel
                  value={selectedTab}
                  index={3}
                  text="Use 120 or 240 frames per second setting on your phone camera. Slow-motion video yields best results!"
                  videoUrl={videoFps}
                />
                <VerticalTabPanel
                  value={selectedTab}
                  index={4}
                  text="Run at your normal-to-fast jogging pace. Not fast-walking or sprinting."
                  videoUrl={videoPace}
                />
                <VerticalTabPanel
                  value={selectedTab}
                  index={5}
                  text="Short video showing 15-30 steps of running is sufficient."
                  videoUrl={videoLength}
                />
              </Grid>
            </Grid>
          </Paper>
          {isUploading && <LinearProgress variant="determinate" value={progress} />}
          <form onSubmit={handleUpload} noValidate>
            {!isUploading && (
              <>
                <Grid container spacing={1} alignItems="center">
                  {user && (
                    <Grid xs={12} md={'auto'} item>
                      <Button variant="contained" component="label" fullWidth>
                        Choose Video
                        <input type="file" onChange={handleFileChange} accept="video/*" ref={inputFileRef} required hidden />
                      </Button>
                    </Grid>
                  )}
                  {previewUrl && (
                    <Grid xs={12} md={'auto'}item>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => setPreviewDialogOpen(true)}
                        sx={{ whiteSpace: isSmallScreen ? 'nowrap' : 'normal' }}
                      >
                        {isSmallScreen ? 'Preview Selection' : 'View Selected Video'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <StyledDialog open={previewDialogOpen} onClose={() => setPreviewDialogOpen(false)}>
                  <video width="100%"
                    style={{ maxHeight: '90vh' }}
                    controls
                    autoPlay
                    muted
                    src={previewUrl}>
                    Your browser does not support the video tag.
                  </video>
                </StyledDialog>
                <FormControl fullWidth required sx={{ mt: 2 }}>
                  <InputLabel id="goal-label">Select your goal...</InputLabel>
                  <Select
                    labelId="goal-label"
                    id="goal-select"
                    value={goal}
                    label="Select your goal..."
                    onChange={(e) => setGoal(e.target.value)}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="compete">Competitive Runner</MenuItem>
                    <MenuItem value="health">Running to Stay Healthy</MenuItem>
                    <MenuItem value="weight">Running to Lose Weight</MenuItem>
                    <MenuItem value="fun">Running for Fun</MenuItem>
                    <MenuItem value="sports">Running as Part of Playing Sports</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Symptoms"
                    variant="outlined"
                    placeholder="E.g. 'pain in lower-inner part of left knee after a long run'"
                    value={symptomsText}
                    onChange={(event) => setSymptomsText(event.target.value)}
                  />
                </FormControl>
                <Grid item xs={12}>
                  {user ? (
                    <Button
                      fullWidth={isSmallScreen}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      disabled={!goal || !file}
                      onClick={() => logEvent(analytics, 'button_click', { button_name: 'upload_video' })}>
                      Upload
                    </Button>
                  ) : (
                    <Button
                      fullWidth={isSmallScreen}
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => {
                        logEvent(analytics, 'button_click', { button_name: 'prompt_sign_in' });
                        navigate('/signin', { state: { from: '/upload' } });
                      }}>
                      Sign in to upload your video
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </form>
        </>
      )}
      </Container>
  );
}

export default UploadPage;
