import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, orderBy, where, query } from 'firebase/firestore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { styled } from '@mui/system';
import { logEvent } from 'firebase/analytics';
import PendingImage from './PendingImage';
import ResultDetailChipBar from './ResultDetailChipBar';
import ResultsChat from './ResultsChat';
import TableRowMetric from './TableRowMetric';
import UserContext from './UserContext';
import VideoTimestampCarousel from './VideoTimestampCarousel';
import Feedback from './Feedback';
import { toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Box, Grid, Typography, CircularProgress, Container,
  Fab, Hidden }
  from '@mui/material';

const Fabs = styled(Box)({
  position: 'fixed',
  bottom: 16,
  right: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 4
});

const feedbackMessages = {
  "not-running": "not a running video",
  "low-quality": "video quality is low",
  "poor-lighting": "poor video lighting",
  "bad-angle": "incorrect video angle",
  "bad-clothing": "clothing obstructing view",
};

const ResultDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const { user, db, analytics } = useContext(UserContext);
  const [submission, setSubmission] = useState(null);
  const { userId, submissionId } = useParams();
  const actualUserId = userId || user.uid; 

  useEffect(() => {
    document.title = "Forma | Result";

    const fetchSubmissionData = async () => {
      try {
        const submissionDocRef = doc(db, 'submissions', submissionId);
        const messagesQuery = query(
          collection(db, 'messages'),
          where('submissionId', '==', submissionId),
          where('subjectId', '==', actualUserId),
          orderBy('timestamp', 'asc')
        );
  
        // Fetch submission and messages in parallel
        const [submissionDocSnapshot, messagesSnapshot] = await Promise.all([
          getDoc(submissionDocRef),
          getDocs(messagesQuery),
        ]);

        if (submissionDocSnapshot.exists()) {
          const fetchedSubmissionData = submissionDocSnapshot.data();
          let metrics = [];
          let highlights = [];
          let tips = [];
          let messages = [];

          if (fetchedSubmissionData.results) {
            metrics = calculateMetrics(fetchedSubmissionData.results);
            highlights = fetchedSubmissionData.results.highlights.sort((a, b) => b.isGood - a.isGood);
            tips = fetchedSubmissionData.results.tips;
            messages = messagesSnapshot.docs.map((doc) => {
              const messageData = doc.data();
              return {
                id: doc.id,
                text: messageData.text,
                timestamp: messageData.timestamp,
                isSenderUser: messageData.senderId === fetchedSubmissionData.userId, // Augment the message
              };
            });
          }

          setSubmission({
            id: submissionDocSnapshot.id,
            highlights,
            tips,
            metrics,
            messages,
            ...fetchedSubmissionData,
          });
          // console.log('setSubmission: ' + JSON.stringify(submissionDocSnapshot.data(), null, 2));
        } else {
          console.error('Submission not found:', submissionId);
          logEvent(analytics, 'page_load_error', {error: 'not_found', page: 'results_detail'});
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
        toast.error('Error fetching assessment result.', { toastId: 'resultDetailError' });
        logEvent(analytics, 'page_load_error', {error: error.message, page: 'results_detail'});
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissionData();
  }, [actualUserId, db, analytics, submissionId]);

  function calculateMetrics(results) {
    let metrics = [];
    if (!results) {
      return metrics;
    }

    if (results.sideViewData) {
      // Handling side view data
      const { LR, MSt, LSt } = results.sideViewData;
      metrics.push(
        {
          name: 'Foot Strike Angle',
          value: LR.footStrikeAngle,
          range: { lower: 0, upper: 5 },
          image: 'metric-foot-strike.jpg',
          description: 'Angle at which foot contacts the ground. Land with midfoot instead of heel to reduce joint load.'
        },
        {
          name: 'Tibia Angle',
          value: LR.tibiaAngle,
          range: { lower: 85, upper: 95 },
          image: 'metric-tibia.jpg',
          description: 'Angle between shin and ground during contact (loading response phase). Extending tibia too far forward can increase joint stress.'
        },
        {
          name: 'Overstride',
          value: LR.degreeOfOverstriding,
          range: { lower: 0, upper: 10 },
          image: 'metric-overstride.jpg',
          description: 'Distance from center of mass to landing location. Land under center of mass, not in front of it, to improve efficiency.'
        },
        {
          name: 'Knee Flexion',
          value: MSt.kneeFlexionAngle,
          range: { lower: 45, upper: Infinity },
          image: 'metric-knee-flex.jpg',
          description: 'Maximum angle of knee bend. Not bending knees enough can indicate poor shock absorption.'
        },
        {
          name: 'Heel Height',
          value: MSt.heelHeight,
          range: { lower: 90, upper: Infinity },
          image: 'metric-heel-height.jpg',
          description: 'How high the heels goes up. Heel should reach the knee height for proper glute engagement.'
        },
        {
          name: 'Vertical Displacement',
          value: MSt.verticalDisplacement,
          valueRight: MSt.verticalDisplacement,
          range: { lower: 0, upper: 8 },
          image: 'metric-vert-osc.jpg',
          description: 'How much body is moving up and down. Excessive vertical motion reduces efficiency and increases joint load.'
        },
        {
          name: 'Trunk Lean',
          value: LSt.trunkLeanAngle,
          range: { lower: 7, upper: 20 },
          image: 'metric-trunk-lean.jpg',
          description: 'Angle of trunk leaning forward or back. Leaning forward helps increase cadence and land under center of mass.'
        },
        {
          name: 'Hip Extension',
          value: LSt.hipExtensionAngle,
          range: { lower: 20, upper: Infinity },
          image: 'metric-hip-ext.jpg',
          description: 'How far back the hip pushes. Glutes should actively work to push off and extend the hip back.'
        },
      );
    } else if (results.backViewData) {
      // Handling back view data
      const { MSt, IC, MSw } = results.backViewData;
      metrics.push(
        {
          name: 'Base of Support',
          value: MSt.baseOfSupport,
          range: { lower: 5, upper: Infinity },
          image: 'metric-base-of-support.jpg',
          description: "Horizontal space between landing locations of left and right feet. 'Running on a tight rope' with narrow base of support increases stress and overuse of joints."
        },
        {
          name: 'Knee Window',
          value: MSt.kneeWindow,
          range: { lower: 8, upper: Infinity },
          image: 'metric-knee-window.jpg',
          description: "Horizontal space between the knees. There should always be a visible gap at all times during running. Knees should not be caving in."
        },
        {
          name: 'Toe Out',
          valueLeft: MSt.toeOutAngle.left,
          valueRight: MSt.toeOutAngle.right,
          range: { lower: 5, upper: 10 },
          image: 'metric-toe-out.jpg',
          description: "How much the toes are pointing out or inwards. Toes should be slightly out. Toes should not be visible on the inside looking from the back."
        },
        {
          name: 'Pelvic Drop',
          valueLeft: IC.pelvicDrop.left,
          valueRight: IC.pelvicDrop.right,
          range: { lower: 0, upper: 5 },
          image: 'metric-pelvic-drop.jpg',
          description: "How much the pelvis drops down on one side at each step. Excessive drop on either side can indicate weakness and increased injury risk."
        },
        {
          name: 'Heel Eversion',
          valueLeft: IC.heelEversion.left,
          valueRight: IC.heelEversion.right,
          range: { lower: 0, upper: 10 },
          image: 'metric-heel-eversion.jpg',
          description: "How much the vertical ankle line tilts sideways. Excessive tilt to either side (inversion or eversion) can increase injury risk and can be compensated through therapy and/or footwear choice."
        },
        {
          name: 'Heel Whip',
          valueLeft: MSw.heelWhipAngle.left,
          valueRight: MSw.heelWhipAngle.right,
          range: { lower: 0, upper: 20 },
          image: 'metric-heel-whip.jpg',
          description: "How much the sole of the foot twists to the side vs staying vertical when seen from the back. Excessive heel whips can indicate higher injury risk and reduced efficiency."
        },
        {
          name: 'Vertical Displacement',
          valueLeft: MSt.verticalDisplacement.left,
          valueRight: MSt.verticalDisplacement.right,
          range: { lower: 0, upper: 8 },
          image: 'metric-vert-osc.jpg',
          description: 'How much body is moving up and down. Excessive vertical motion reduces efficiency and increases joint load.'
        },
        {
          name: 'Heel Height',
          valueLeft: MSt.heelHeight.left,
          valueRight: MSt.heelHeight.right,
          range: { lower: 90, upper: Infinity },
          image: 'metric-heel-height.jpg',
          description: 'How high the heels goes up. Heel should reach the knee height for proper glute engagement.'
        },
      );
    }

    // Always present metrics
    metrics.push(
      {
        name: 'Cadence',
        value: results.cadence,
        range: { lower: 170, upper: Infinity },
        image: 'metric-cadence.jpg',
        description: 'Number of steps per minute. Increase cadence to reduce stress on joints and improve efficiency.',
      },
      {
        name: 'Treadmill Shaking Degree',
        value: results.treadmillShakingDegree,
        range: { lower: 0, upper: 3 },
        image: 'metric-treadmill-shaking.jpg',
        description: 'How much the treadmill is shaking. Excessive shaking can indicate higher impact on the joints.'
      },
    );

    // Set isOutOfRange for all metrics
    metrics.forEach(metric => {
      metric.isOutOfRange = [metric.value, metric.valueLeft, metric.valueRight]
        .filter(Boolean) // filters out undefined and null values
        .some(value => value < metric.range.lower || value > metric.range.upper);
    });

    metrics.sort((a, b) => {
      // First, sort by whether the metric is out of range
      const rangeComparison = b.isOutOfRange - a.isOutOfRange;
      if (rangeComparison !== 0) return rangeComparison;

      // If both metrics are either in range or out of range, sort by whether the metric has left and right values
      const aValue = a.valueLeft !== undefined && a.valueRight !== undefined ? 1 : 0;
      const bValue = b.valueLeft !== undefined && b.valueRight !== undefined ? 1 : 0;
      return bValue - aValue;
    });
    return metrics;
  }

  const getFeedbackMessage = (feedbackKey) => {
    const feedback = feedbackMessages[feedbackKey] || feedbackKey;
    return `Invalid submission: ${feedback}. Please upload a valid running video.`;
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Your Results
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      ) : !submission ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh">
          <Typography variant="h6" gutterBottom>
            No submission data found.
          </Typography>
          <PendingImage altText="no-videos-uploaded"/>
        </Box>
      ) : submission.status !== 'reviewed' ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="60vh"
          textAlign="center"
        >
          <ResultDetailChipBar submission={submission} />
          <Typography variant="h6" align="center" gutterBottom>
            {submission.status === 'pending' ?
              'Your submission is being reviewed. Keep an eye out for an email with results. :)' :
              getFeedbackMessage(submission.feedback)
            }
          </Typography>
          <PendingImage altText="no-results-yet"/>
        </Box>
      ) : (
        <>
        <ResultDetailChipBar submission={submission} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ResultsChat
                tips={submission.tips}
                highlights={submission.highlights}
                messages={submission.messages}
                subjectId={submission.userId}
                submissionId={submissionId} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Detailed Metrics
              </Typography>
              <TableContainer component={Paper} variant="outlined" elevation={0}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Hidden smDown>
                        <TableCell />
                      </Hidden>
                      <TableCell style={{ fontWeight: 'bold' }}>Metric</TableCell>
                      {submission.videoAngle === 'side' ? (
                        <TableCell style={{ fontWeight: 'bold' }}>Yours</TableCell>
                      ) : (
                        <>
                          <TableCell style={{ fontWeight: 'bold' }} align='center'>Left</TableCell>
                          <TableCell  style={{ fontWeight: 'bold' }} align='center'>Right</TableCell>
                        </>
                      )}
                      <TableCell style={{ fontWeight: 'bold' }}>Range</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submission.metrics.map((metric, index) => (
                      <TableRowMetric key={index} index={index} metric={metric} videoAngle={submission.videoAngle} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} md={6}>
              <VideoTimestampCarousel
                videoUrl={submission.videoUrl}
                videoAngle={submission.videoAngle}
                userId={submission.userId}/>
            </Grid>
          </Grid>
          <Fabs>
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              size="medium"
              component={RouterLink}
              to="/upload"
              onClick={() => logEvent(analytics, 'button_click', { button_name: 'upload_another' })}>
              <FileUploadOutlinedIcon sx={{ mr: 1 }} />
              Next: upload {submission.videoAngle === 'side' ? 'back' : 'side'} view
            </Fab>
            <Feedback />
          </Fabs>
        </>
      )}
    </Container>
  );
};

export default ResultDetailPage;
