import React, { useState, useContext } from 'react'
import UserContext from './UserContext';
import { logEvent } from 'firebase/analytics';
import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableRow, TableCell, Chip, Collapse, IconButton, Box, Grid, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

function flagged(metricValue, range) {
  return metricValue < range.lower || metricValue > range.upper;
}

function createMetricChip(metricValue, range) {
  return (
    <Chip
      label={metricValue}
      color={flagged(metricValue, range) ? 'error' : 'default'}
    />
  );
}

const Row = styled(TableRow)`
  &:last-child .MuiTableCell-root {
    borderBottom: none !important;
  }
  &:hover {
    cursor: ${props => (props.hoverable === 1 ? 'pointer' : 'default')};
  }
`;

const Cell = styled(TableCell)({
  borderBottom: 'none !important',
});

export default function TableRowMetric({metric, videoAngle, index}) {
  const [isRowExpanded, setIsRowExpanded] = useState(index === 0);
  const { analytics } = useContext(UserContext);
  const isFlagged = metric.value
      ? flagged(metric.value, metric.range)
      : (flagged(metric.valueRight, metric.range) || flagged(metric.valueLeft, metric.range));
  const metricValues = videoAngle === 'side' ? (
    <Cell>
      {createMetricChip(metric.value, metric.range)}
    </Cell>
  ) : (
    metric.valueLeft && metric.valueRight ? (
      <>
        <Cell align='center'>
          {createMetricChip(metric.valueLeft, metric.range)}
        </Cell>
        <Cell align='center'>
          {createMetricChip(metric.valueRight, metric.range)}
        </Cell>
      </>
    ) : (
      <Cell colSpan={2} align='center'>
        {createMetricChip(metric.value, metric.range)}
      </Cell>
    )
  );

  return (
    <>
      <Row hoverable={1} onClick={() => {
        setIsRowExpanded(!isRowExpanded);
        logEvent(analytics, 'metric_click', { metric: metric.name, flagged: isFlagged });
      }}>
        <Hidden smDown>
          <Cell>
            <IconButton size="small">
              {isRowExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Cell>
        </Hidden>
        <Cell>{metric.name}</Cell>
        {metricValues}
        <Cell>{`${metric.range.lower}${isFinite(metric.range.upper) ? `-${metric.range.upper}` : '+'}`}</Cell>
      </Row>
      <Row>
        <TableCell
            style={{ padding: 0 }}
            colSpan={videoAngle === 'back' ? 5 : 4}>
          <Collapse in={isRowExpanded} timeout="auto">
            <Box sx={{ display: 'flex' }}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box position="relative" width="100%">
                    <Box component="img"
                         src={`/images/${metric.image}`}
                         alt={metric.name}
                         sx={{ width: '100%', height: 'auto', display: 'block' }}
                    />
                    <Box position="absolute"
                         bottom={0}
                         right={0}
                         color="white"
                         bgcolor="rgba(0, 0, 0, 0.5)"
                         p={0.5}
                    >
                      Model Photo
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body1">{metric.description}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </Row>
    </>
  );
}
