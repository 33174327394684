import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check'; 

const FeatureListItem = ({ text }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <CheckIcon color="primary" fontSize="large" />
      </ListItemIcon>
      <ListItemText 
        primary={<Typography variant="body1" style={{ fontSize: '1.25rem' }}>{text}</Typography>} />
    </ListItem>
  );
};

const PricingPage = () => {
  useEffect(() => {
    document.title = "Forma | Pricing";
  }, []);
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Choose Your Plan
      </Typography>
      <Typography variant="h6" align="center" color="text.secondary" paragraph>
        Select the plan that best fits your needs
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography gutterBottom variant="h5" align="center">
                Free
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 1 }}>
                <Typography variant="h3" color="primary">
                  $0
                </Typography>
                <Typography variant="subtitle1" align="center" color="text.secondary">
                  limited time
                </Typography>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'auto', margin: '0 auto' }}>
                <FeatureListItem text="10 Video Reviews" />
                <FeatureListItem text="20 Coach Messages" />
              </List>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="outlined"
                component={RouterLink}
                to="/upload"
              >
                Try For Free
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* A la carte Tier */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography gutterBottom variant="h5" align="center">
                A La Carte
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 1 }}>
                <Typography variant="h3" color="primary">
                  $29.99
                </Typography>
                <Typography variant="subtitle1" align="center" color="text.secondary">
                  pay as you go
                </Typography>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'auto', margin: '0 auto' }}>
                <FeatureListItem text="25 Video Reviews" />
                <FeatureListItem text="50 Coach Messages" />
              </List>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                component={RouterLink}
                to="/upload"
              >
                Try For Free
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Unlimited Tier */}
        <Grid item xs={12} sm={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography gutterBottom variant="h5" align="center">
                Unlimited
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 1 }}>
                <Typography variant="h3" color="primary">
                  $19.99
                </Typography>
                <Typography variant="subtitle1" align="center" color="text.secondary">
                  per month
                </Typography>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'auto', margin: '0 auto' }}>
                <FeatureListItem text="Unlimited Video Reviews" />
                <FeatureListItem text="Unlimited Coach Messages" />
              </List>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                component={RouterLink}
                to="/upload"
              >
                Try For Free
              </Button>
            </CardActions>
          </Card>
        </Grid>

      </Grid>
    </Container>
  );
};

export default PricingPage;
